<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="详情"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <div class="doc-title">
        {{ docData.fileName }}
      </div>
      <div class="doc-desc">
        1233
      </div>
      <div v-if="docData.fileDTO.length > 0" class="doc-download">
        <div class="data-icon">
          <img :src="require(`@/assets/images/equipment/default.png`)" />
        </div>
        <div class="data-title">
          {{ docData.fileDTO[0].name }}
        </div>
        <div class="data-down" @click="downloadDocument">
          <img :src="require(`@/assets/images/equipment/download.png`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentDocDetail",
  data() {
    return {
      docData: {}
    };
  },
  created() {
    this.$nextTick(() => {
      this.docData = this.$route.params.doc;
    });
  },
  methods: {
    downloadDocument() {
      if (!window.android.downLoadFile) {
        this.$toast.fail("此版本不支持导出，请更新后使用");
        return;
      }
      const [file] = this.docData.fileDTO;
      const fileName = `${file.name}`;
      const fileUrl = window.globalConfig.VUE_APP_FILE_URL;
      const url = `${fileUrl}/file/download?downloadFlag=true&fileName=${file.id}`;
      window.android.downLoadFile(url, fileName);
    },
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 750px;
  overflow: hidden;

  .container {
    height: 750px;
    margin-top: 58px;
    .doc-title {
      font-size: 16px;
      color: #2e2e4d;
      font-weight: 600;
      padding: 18px 16px 12px;
    }
    .doc-desc {
      height: 168px;
      margin: 0 16px 12px;
      padding: 12px 16px;
      background: #f5f7fa;
      border-radius: 6px;
      overflow: hidden;
    }
    .doc-download {
      padding: 16px;
      margin-top: 14px;
      > div {
        float: left;
        vertical-align: middle;
        font-size: 14px;
        color: #2e2e4d;
        font-weight: 600;
        &:first-child {
          margin-right: 4px;
        }
      }
      .data-title {
        width: 250px;
      }
      .data-down {
        float: right;
      }
    }
  }
}
</style>
